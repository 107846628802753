.footer-container {
  background-color: #242424;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
  color: #fff;
}

.footer-logo {
  color: rgb(124 30 30);
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
  font-weight: 600;
  color: #fff;
}

a:link {
  color: rgb(124 30 30);
  font-weight: 600;
}

.copyright {
  color: #fff !important;
}

.logo-Anbako-footer {
  width: 250px;
  display: flex;
  padding-bottom: 30px;
}

.footer-text-adres {
  padding-bottom: 20px;
}

.footer-names-tel {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}
