.cards_container {
  background-color: #fff;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  padding: 50px 30px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 70px;
}

.main_heading {
  color: rgb(124, 30, 30);
  font-size: 48px;
  padding: 35px;
  text-align: center;
  font-weight: 700;
}

.over-ons-text {
  font-size: 20px;
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #722622;
}

.contact-card-container {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  width: 100%;
  height: auto;
  box-shadow: 20px 14px 20px 13px #00000042;
  border-radius: 10px;
  margin: 100px 50px;
}

.contact-foto {
  max-width: 30%;
  height: auto;
  margin: 20px;
  border-radius: 50%;
}

.contact-naam {
  font-size: 30px;
  color: #722622;
  font-weight: 700;
}

.ct {
  color: rgba(0, 0, 0, 0.9) !important;
}

.ct:hover {
  border-bottom: 2px solid #722622;
}

.contact-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  font-size: 20px;
}

@media screen and (max-width: 1400px) {
  .over-ons-text {
    font-size: 16px;
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #722622;
  }

  .contact-card-container {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    width: 80%;
    height: auto;
    box-shadow: 20px 14px 20px 13px #00000042;
    border-radius: 10px;
    margin: 100px 50px;
  }

  .contact-foto {
    max-width: 20%;
    height: auto;
    margin: 20px;
    border-radius: 50%;
  }

  .contact-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .contact-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    font-size: 16px;
  }

  .contact-naam {
    font-size: 20px;
    color: #722622;
    font-weight: 700;
  }

  .contact-foto {
    max-width: 30%;
    height: auto;
    margin: 20px;
    border-radius: 50%;
  }
}

@media screen and (max-width: 800px) {
  .contact-card-container {
    margin: 40px 50px;
  }
}

@media screen and (max-width: 500px) {
  .main_heading {
    font-size: 35px;
    padding: 10px;
    padding-top: 35px;
    padding-bottom: 15px;
  }

  .text-wrapper {
    padding: 35px;
  }

  .contact-text {
    padding-left: 12px;
  }

  .contact-naam {
    font-size: 16px;
  }

  .ct {
    font-size: 14px;
  }
}
