* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.cookieinfo{
  min-height: 50px !important;
  height: 60px !important;
  background:rgb(114 38 34) !important;
}

.cookieinfo-close{
  background: #fff !important;
}