.contact-form-container {
  background: url("./../Images/contact.png") center center/cover no-repeat,
    linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.cf-content {
  padding: 80px;
}

.cf-text {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  padding: 50px;
  padding-bottom: 0px;
  text-align: center;
}

.contact-form-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

@media screen and (max-width: 1300px) {
  .cf-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  .contact-form-container > h1 {
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
  }
}

@media screen and (max-width: 400px) {
  .contact-form-container > h1 {
    color: #fff;
    font-size: 60px;
    margin-top: -100px;
  }

  .cf-content {
    padding: 42px;
  }
}
