.diensten-container {
  background: url("./../Images/diensten.jpg") center center/cover no-repeat,
    linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.diensten-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 80px;
}

.diensten-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.diensten-content-text {
  display: flex;
  align-items: center;
}

.diensten-text {
  font-size: 20px;
}

.diensten-text-b {
  padding-left: 25px;
  padding-bottom: 20px;
  font-weight: 400;
}

.diensten-list {
  font-weight: 600;
}

.diensten-list-2 {
  font-weight: 600;
  padding-bottom: 20px;
}

.diensten-ul {
  list-style-type: none;
}

.diensten-ul li::before {
  content: "\3e";
  font-family: "Font Awesome 5 Free";
  padding: 0 10px 0 0;
  color: #722622;
}

.diensten-foto {
  padding-right: 30px;
  max-width: 600px;
  max-height: 400px;
}

@media screen and (max-width: 1500px) {
  .diensten-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 1300px) {
  .diensten-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 80px;
    align-items: center;
  }

  .diensten-text {
    font-size: 18px;
  }

  .diensten-foto {
    padding-right: 30px;
    max-width: 500px;
    max-height: 400px;
  }
}

@media screen and (max-width: 550px) {
  .diensten-foto {
    padding: 20px;
    max-width: 450px;
    max-height: 350px;
  }

  .diensten-container > h1 {
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
  }
}

@media screen and (max-width: 450px) {
  .diensten-list {
    font-weight: 600;
    position: relative;
  }

  .diensten-ul li::before {
    content: "\3e";
    font-family: "Font Awesome 5 Free";
    padding: 0 10px 0 0;
    color: #722622;
    position: absolute;
    left: -20px;
  }

  .diensten-text-b {
    padding-left: 0px;
    padding-bottom: 20px;
    font-weight: 400;
  }

  .diensten-foto {
    padding: 20px;
    max-width: 380px;
    max-height: 350px;
  }

  .diensten-container > h1 {
    color: #fff;
    font-size: 60px;
    margin-top: -100px;
  }

  .diensten-text {
    font-size: 16px;
    padding-left: 25px;
  }

  .diensten-content {
    padding: 50px;
  }
}
